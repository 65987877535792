import React from "react"

class FourOhFour extends React.Component {
  render() {
    return (
      <section>
        <h1>Page not found</h1>
      </section>
    )
  }
}

export default FourOhFour